import mod from "../lib/src/api.js";

export default mod;
export const Alternation = mod.Alternation;
export const Alternative = mod.Alternative;
export const CstParser = mod.CstParser;
export const EMPTY_ALT = mod.EMPTY_ALT;
export const EOF = mod.EOF;
export const EarlyExitException = mod.EarlyExitException;
export const EmbeddedActionsParser = mod.EmbeddedActionsParser;
export const GAstVisitor = mod.GAstVisitor;
export const LLkLookaheadStrategy = mod.LLkLookaheadStrategy;
export const Lexer = mod.Lexer;
export const LexerDefinitionErrorType = mod.LexerDefinitionErrorType;
export const MismatchedTokenException = mod.MismatchedTokenException;
export const NoViableAltException = mod.NoViableAltException;
export const NonTerminal = mod.NonTerminal;
export const NotAllInputParsedException = mod.NotAllInputParsedException;
export const Option = mod.Option;
export const Parser = mod.Parser;
export const ParserDefinitionErrorType = mod.ParserDefinitionErrorType;
export const Repetition = mod.Repetition;
export const RepetitionMandatory = mod.RepetitionMandatory;
export const RepetitionMandatoryWithSeparator = mod.RepetitionMandatoryWithSeparator;
export const RepetitionWithSeparator = mod.RepetitionWithSeparator;
export const Rule = mod.Rule;
export const Terminal = mod.Terminal;
export const VERSION = mod.VERSION;
export const clearCache = mod.clearCache;
export const createSyntaxDiagramsCode = mod.createSyntaxDiagramsCode;
export const createToken = mod.createToken;
export const createTokenInstance = mod.createTokenInstance;
export const defaultLexerErrorProvider = mod.defaultLexerErrorProvider;
export const defaultParserErrorProvider = mod.defaultParserErrorProvider;
export const generateCstDts = mod.generateCstDts;
export const getLookaheadPaths = mod.getLookaheadPaths;
export const isRecognitionException = mod.isRecognitionException;
export const serializeGrammar = mod.serializeGrammar;
export const serializeProduction = mod.serializeProduction;
export const tokenLabel = mod.tokenLabel;
export const tokenMatcher = mod.tokenMatcher;
export const tokenName = mod.tokenName;
